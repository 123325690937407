import {
  TP,
  ARTIST_TYPE_ID,
  COMPANY_TYPE_ID,
  AGENCY_TYPE_ID,
  CASTING_TYPE_ID,
  ACADEMICS_TYPE_ID,
  JOURNALIST_TYPE_ID,
} from 'constants/index';
import { ARTIST_PLANS, AOS_PLANS, AGENCY_PLANS, CASTING_TOOL_PLANS } from 'containers/Accounts/constants';
import { COMPONENTS } from 'components/Globals/Analytics/constants';

import route from 'constants/routes';

export const LP_PRO_TOOL_ITEM = {
  CASTING_TOOL: 'casting_tool',
  COMPANY_PAGE_MANAGEMENT: 'company_page_mngmnt',
  AP_MANAGEMENT: 'ap_mngmnt',
  AGENCY_ROSTER: 'agency_roster',
  RENTALS: 'rentals',
  OB_ARCHIVES: 'ob_archives',
  AGENCY_ROSTER_MNGMNT: 'agency_roster_mngmnt',
  PERFORMANCE_EXPORT: 'perf_export',
  REQUEST_RED_MASK: 'request_red_mask',
};

export const LP_FEATURED_FEATURES = [
  { feature: LP_PRO_TOOL_ITEM.CASTING_TOOL, label: `${TP}.FN_CASTINGTOOL` },
  { feature: LP_PRO_TOOL_ITEM.AP_MANAGEMENT, label: `${TP}.LP_ARTIST_PROFILE_MANAGEMENT` },
  { feature: LP_PRO_TOOL_ITEM.AGENCY_ROSTER_MNGMNT, label: `${TP}.LP_AGENCY_ROSTER` },
  { feature: LP_PRO_TOOL_ITEM.RENTALS, label: `${TP}.FN_TAB_RENTALS` },
  { feature: LP_PRO_TOOL_ITEM.OB_ARCHIVES, label: `${TP}.LP_ARCHIVES` },
];

export const QNA_TRANSLATION_KEYS = {
  artists: [
    { q: 'FAQ_Q_ARTISTS_1', a: 'FAQ_A_ARTISTS_1' },
    { q: 'FAQ_Q_ARTISTS_2', a: 'FAQ_A_ARTISTS_2' },
    { q: 'FAQ_Q_ARTISTS_3', a: 'FAQ_A_ARTISTS_3' },
    { q: 'FAQ_Q_ARTISTS_4', a: 'FAQ_A_ARTISTS_4' },
    { q: 'FAQ_Q_ARTISTS_5', a: 'FAQ_A_ARTISTS_5' },
    { q: 'FAQ_Q_ARTISTS_6', a: 'FAQ_A_ARTISTS_6' },
    { q: 'FAQ_Q_ARTISTS_7', a: 'FAQ_A_ARTISTS_7' },
    { q: 'FAQ_Q_ARTISTS_8', a: 'FAQ_A_ARTISTS_8' },
    { q: 'FAQ_Q_ARTISTS_9', a: 'FAQ_A_ARTISTS_9' },
    { q: 'FAQ_Q_ARTISTS_10', a: 'FAQ_A_ARTISTS_10' },
    { q: 'FAQ_Q_ARTISTS_11', a: 'FAQ_A_ARTISTS_11' },
    { q: 'FAQ_Q_ARTISTS_12', a: 'FAQ_A_ARTISTS_12' },
    { q: 'FAQ_Q_ARTISTS_13', a: 'FAQ_A_ARTISTS_13' },
    { q: 'FAQ_Q_ARTISTS_14', a: 'FAQ_A_ARTISTS_14' },
    { q: 'FAQ_Q_ARTISTS_15', a: 'FAQ_A_ARTISTS_15' },
    { q: 'FAQ_Q_ARTISTS_16', a: 'FAQ_A_ARTISTS_16' },
    { q: 'FAQ_Q_ARTISTS_17', a: 'FAQ_A_ARTISTS_17' },
    { q: 'FAQ_Q_ARTISTS_18', a: 'FAQ_A_ARTISTS_18' },
    { q: 'FAQ_Q_ARTISTS_19', a: 'FAQ_A_ARTISTS_19' },
    { q: 'FAQ_Q_ARTISTS_20', a: 'FAQ_A_ARTISTS_20' },
    { q: 'FAQ_Q_ARTISTS_21', a: 'FAQ_A_ARTISTS_21' },
    { q: 'FAQ_Q_ARTISTS_22', a: 'FAQ_A_ARTISTS_22' },
    { q: 'FAQ_Q_ARTISTS_23', a: 'FAQ_A_ARTISTS_23' },
    { q: 'FAQ_Q_ARTISTS_24', a: 'FAQ_A_ARTISTS_24' },
    { q: 'FAQ_Q_ARTISTS_25', a: 'FAQ_A_ARTISTS_25' },
    { q: 'FAQ_Q_ARTISTS_26', a: 'FAQ_A_ARTISTS_26' },
    { q: 'FAQ_Q_ARTISTS_27', a: 'FAQ_A_ARTISTS_27' },
    { q: 'FAQ_Q_ARTISTS_28', a: 'FAQ_A_ARTISTS_28' },
    { q: 'FAQ_Q_ARTISTS_29', a: 'FAQ_A_ARTISTS_29' },
    { q: 'FAQ_Q_ARTISTS_30', a: 'FAQ_A_ARTISTS_30' },
    { q: 'FAQ_Q_ARTISTS_31', a: 'FAQ_A_ARTISTS_31' },
    { q: 'FAQ_Q_ARTISTS_32', a: 'FAQ_A_ARTISTS_32' },
    { q: 'FAQ_Q_ARTISTS_33', a: 'FAQ_A_ARTISTS_33' },
    { q: 'FAQ_Q_ARTISTS_34', a: 'FAQ_A_ARTISTS_34' },
  ],
  agencies: [
    { q: 'FAQ_Q_AGENCY_1', a: 'FAQ_A_AGENCY_1' },
    { q: 'FAQ_Q_AGENCY_2', a: 'FAQ_A_AGENCY_2' },
    { q: 'FAQ_Q_AGENCY_3', a: 'FAQ_A_AGENCY_3' },
    { q: 'FAQ_Q_AGENCY_4', a: 'FAQ_A_AGENCY_4' },
  ],
  casting: [
    { q: 'FAQ_Q_CASTING_1', a: 'FAQ_A_CASTING_1' },
    { q: 'FAQ_Q_CASTING_2', a: 'FAQ_A_CASTING_2' },
    { q: 'FAQ_Q_CASTING_3', a: 'FAQ_A_CASTING_3' },
    { q: 'FAQ_Q_CASTING_4', a: 'FAQ_A_CASTING_4' },
    { q: 'FAQ_Q_CASTING_5', a: 'FAQ_A_CASTING_5' },
    { q: 'FAQ_Q_CASTING_6', a: 'FAQ_A_CASTING_6' },
  ],
};

export const DETAILS_PAGE_TYPES = {
  companies: {
    title: `${TP}.LP_AOS_DETAILS_TITLE`,
    titleImageSrc: '/images/landing-pages/featured-images/company-management.png',
    blackBarImageSrc: '/images/landing-pages/laptops/industry_professional.png',
    blackBarTitle: `${TP}.LP_CASTING_TOOL_BB_TITLE`,
    blackBarSubtitle: `${TP}.LP_CASTING_TOOL_BB_SUBTITLE`,
    middleText: `${TP}.LP_CASTING_YELLOW_TITLE`,
    isBlackBarReversed: false,
    qnaTitle: `${TP}.LP_COMPANY_MANAGEMENT_FEATURES`,
    qnaI18nKey: `${TP}.LP_COMPANY_PAGE_MANAGEMENT_QNA`,
    buttonLabel: `${TP}.UPDATE_PROFILE`,
    buttonLink: route.DETAILS_ARTISTS,
    planTitle: `${TP}.LP_AOS_PRO_PLAN_TITLE`,
    plans: AOS_PLANS,
    registerType: COMPANY_TYPE_ID,
  },
  agencies: {
    title: `${TP}.LP_AGENCY_DETAILS_TITLE`,
    titleImageSrc: '/images/landing-pages/featured-images/agency-roster.png',
    blackBarImageSrc: '/images/landing-pages/laptops/industry_professional.png',
    blackBarTitle: `${TP}.LP_CASTING_TOOL_BB_TITLE`,
    blackBarSubtitle: `${TP}.LP_CASTING_TOOL_BB_SUBTITLE`,
    middleText: `${TP}.LP_CASTING_YELLOW_TITLE`,
    isBlackBarReversed: false,
    qnaTitle: `${TP}.LP_AGENCY_MANAGEMENT_FEATURES`,
    qnaI18nKey: `${TP}.LP_AGENCY_ROSTER_MANAGEMENT_QNA`,
    buttonLabel: `${TP}.UPDATE_PROFILE`,
    buttonLink: route.DETAILS_ARTISTS,
    planTitle: `${TP}.LP_ROSTER_PRO_PLAN_TITLE`,
    plans: AGENCY_PLANS,
    registerType: AGENCY_TYPE_ID,
  },
  artists: {
    title: `${TP}.LP_ARTIST_DETAILS_TITLE`,
    titleImageSrc: '/images/landing-pages/featured-images/artist-management.png',
    blackBarImageSrc: '/images/landing-pages/laptops/casting_tool.png',
    blackBarTitle: `${TP}.LP_ARTIST_BB_TITLE`,
    blackBarSubtitle: `${TP}.LP_ARTIST_BB_SUBTITLE`,
    middleText: `${TP}.LP_CASTING_YELLOW_TITLE`,
    isBlackBarReversed: false,
    qnaTitle: `${TP}.LP_ARTIST_PROFILE_MANAGEMENT`,
    qnaI18nKey: `${TP}.LP_ARTIST_PROFILE_MANAGEMENT_QNA`,
    buttonLabel: `${TP}.ACTION_GOTO_CASTING_TOOL`,
    buttonLink: route.CASTING_TOOL,
    planTitle: `${TP}.LP_ARTIST_PRO_PLAN_TITLE`,
    plans: ARTIST_PLANS,
    registerType: ARTIST_TYPE_ID,
  },
  casting: {
    title: `${TP}.LP_CASTING_DETAILS_TITLE`,
    titleImageSrc: '/images/landing-pages/featured-images/casting-tool-upd.png',
    blackBarImageSrc: '/images/landing-pages/laptops/industry_professional.png',
    blackBarTitle: `${TP}.LP_CASTING_TOOL_BB_TITLE`,
    blackBarSubtitle: `${TP}.LP_CASTING_TOOL_BB_SUBTITLE`,
    middleText: `${TP}.LP_CASTING_YELLOW_TITLE`,
    isBlackBarReversed: false,
    qnaTitle: `${TP}.LP_CASTING_TOOL_FEATURES`,
    qnaI18nKey: `${TP}.LP_CASTING_QNA`,
    buttonLabel: `${TP}.UPDATE_PROFILE`,
    buttonLink: route.DETAILS_ARTISTS,
    planTitle: `${TP}.NEW_LANDING_PAGE_CASTINGTOOL_PRICE_TITLE`,
    plans: CASTING_TOOL_PLANS,
    registerType: CASTING_TYPE_ID,
  },
};

export const COHORT_LIST = [
  {
    key: 'all',
    path: 'all',
    label: `${TP}.LP_ALL_PRO_TOOLS`,
    sectionLabel: t => t(`${TP}.LP_ALL_PRO_TOOLS_FOR_IP`),
    component: COMPONENTS.ALL_PRO_TOOLS, // Used for analytics
    features: [
      LP_PRO_TOOL_ITEM.CASTING_TOOL,
      LP_PRO_TOOL_ITEM.AP_MANAGEMENT,
      LP_PRO_TOOL_ITEM.COMPANY_PAGE_MANAGEMENT,
      LP_PRO_TOOL_ITEM.AGENCY_ROSTER_MNGMNT,
      LP_PRO_TOOL_ITEM.RENTALS,
      LP_PRO_TOOL_ITEM.OB_ARCHIVES,
      LP_PRO_TOOL_ITEM.PERFORMANCE_EXPORT,
      LP_PRO_TOOL_ITEM.REQUEST_RED_MASK,
    ],
    registerType: ARTIST_TYPE_ID,
  },
  {
    key: 'aos',
    path: 'companies',
    label: `${TP}.LP_FOR_AOS`,
    sectionLabel: t => `${t(`${TP}.LP_PROO_TOOLS_FOR`)} ${t(`${TP}.LP_FOR_AOS`)}`,
    component: COMPONENTS.AOS,
    features: [
      LP_PRO_TOOL_ITEM.CASTING_TOOL,
      LP_PRO_TOOL_ITEM.COMPANY_PAGE_MANAGEMENT,
      LP_PRO_TOOL_ITEM.RENTALS,
      LP_PRO_TOOL_ITEM.OB_ARCHIVES,
    ],
    registerType: COMPANY_TYPE_ID,
  },
  {
    key: 'artists',
    path: 'artists',
    label: `${TP}.LP_ARTISTS_AND_PRO`,
    sectionLabel: t => `${t(`${TP}.LP_PROO_TOOLS_FOR`)} ${t(`${TP}.LP_ARTISTS_AND_PRO`)}`,
    component: COMPONENTS.ARTIST_INDUSTRY_PROFESSIOSNAL,
    features: [LP_PRO_TOOL_ITEM.AP_MANAGEMENT, LP_PRO_TOOL_ITEM.REQUEST_RED_MASK],
    registerType: ARTIST_TYPE_ID,
  },
  {
    key: 'managers',
    path: 'agencies',
    label: `${TP}.LP_FOR_MANAGERS`,
    component: COMPONENTS.MANAGER_AGENCY,
    sectionLabel: t => `${t(`${TP}.LP_PROO_TOOLS_FOR`)} ${t(`${TP}.LP_FOR_MANAGERS`)}`,
    features: [LP_PRO_TOOL_ITEM.AGENCY_ROSTER_MNGMNT, LP_PRO_TOOL_ITEM.CASTING_TOOL, LP_PRO_TOOL_ITEM.OB_ARCHIVES],
    registerType: AGENCY_TYPE_ID,
  },
  {
    key: 'academia',
    path: 'academia',
    label: `${TP}.LP_FOR_EDU`,
    component: COMPONENTS.EDUCATION_ACADEMIA,
    sectionLabel: t => `${t(`${TP}.LP_PROO_TOOLS_FOR`)} ${t(`${TP}.LP_FOR_EDU`)}`,
    features: [LP_PRO_TOOL_ITEM.CASTING_TOOL, LP_PRO_TOOL_ITEM.OB_ARCHIVES],
    registerType: ACADEMICS_TYPE_ID,
  },
  {
    key: 'journalists',
    path: 'journalist',
    label: `${TP}.LP_FOR_JOURNALISTS`,
    component: COMPONENTS.JOURNALIST,
    sectionLabel: t => `${t(`${TP}.LP_PROO_TOOLS_FOR`)} ${t(`${TP}.LP_FOR_JOURNALISTS`)}`,
    features: [LP_PRO_TOOL_ITEM.OB_ARCHIVES, LP_PRO_TOOL_ITEM.CASTING_TOOL, LP_PRO_TOOL_ITEM.PERFORMANCE_EXPORT],
    registerType: JOURNALIST_TYPE_ID,
  },
];

export const PROTOOLS = {
  [LP_PRO_TOOL_ITEM.CASTING_TOOL]: {
    label: `${TP}.FN_CASTINGTOOL`,
    qnaI18nKey: `${TP}.LP_CASTING_QNA`,
    title: `${TP}.FN_CASTINGTOOL`,
    subtitle: `${TP}.FOR_AOS_MAIN_INFO`,
    imageSrc: '/images/landing-pages/featured-images/casting-tool.webp',
  },
  [LP_PRO_TOOL_ITEM.COMPANY_PAGE_MANAGEMENT]: {
    label: `${TP}.LP_COMPANY_PAGE_MANAGEMENT`,
    qnaI18nKey: `${TP}.LP_COMPANY_PAGE_MANAGEMENT_QNA`,
    title: `${TP}.LP_COMPANY_PAGE_MANAGEMENT`,
    subtitle: `${TP}.LANDING_PAGE_NAVIGATION_COMPANIES`,
    imageSrc: '/images/landing-pages/featured-images/company-management.webp',
  },
  [LP_PRO_TOOL_ITEM.AP_MANAGEMENT]: {
    label: `${TP}.LP_ARTIST_PROFILE_MANAGEMENT`,
    qnaI18nKey: `${TP}.LP_ARTIST_PROFILE_MANAGEMENT_QNA`,
    title: `${TP}.LP_ARTIST_PROFILE_MANAGEMENT`,
    subtitle: `${TP}.LP_ARTIST_PROFILE_MANAGEMENT_TEXT`,
    imageSrc: '/images/landing-pages/featured-images/artist-management.webp',
  },
  [LP_PRO_TOOL_ITEM.RENTALS]: {
    label: `${TP}.LP_RENTALS_FEATURE_BTN`,
    qnaI18nKey: `${TP}.LP_RENTALS_QNA`,
    title: `${TP}.FN_TAB_RENTALS`,
    subtitle: `${TP}.LP_RENTALS_TEXT`,
    imageSrc: '/images/landing-pages/featured-images/rentals.webp',
  },
  [LP_PRO_TOOL_ITEM.OB_ARCHIVES]: {
    label: `${TP}.FN_CASTINGTOOL_OPERABASE_ARCHIVES`,
    qnaI18nKey: `${TP}.LP_OPERABASE_ARCHIVES_QNA`,
    title: `${TP}.FN_CASTINGTOOL_OPERABASE_ARCHIVES`,
    subtitle: `${TP}.FOR_ACADEMIA_MAIN_INFO`,
    imageSrc: '/images/landing-pages/featured-images/performance_export.webp',
  },
  [LP_PRO_TOOL_ITEM.AGENCY_ROSTER_MNGMNT]: {
    label: `${TP}.LP_AGENCY_ROSTER_MANAGEMENT`,
    qnaI18nKey: `${TP}.LP_AGENCY_ROSTER_MANAGEMENT_QNA`,
    title: `${TP}.LP_AGENCY_ROSTER_MANAGEMENT`,
    subtitle: `${TP}.LP_AGENCY_ROSTER_MANAGEMENT_TEXT`,
    imageSrc: '/images/landing-pages/featured-images/agency-roster.webp',
  },
  [LP_PRO_TOOL_ITEM.PERFORMANCE_EXPORT]: {
    label: `${TP}.LP_PERFORMANCE_EXPORT`,
    qnaI18nKey: `${TP}.LP_PERFORMANCE_EXPORT_QNA`,
    title: `${TP}.LP_PERFORMANCE_EXPORT_ON_REQUEST`,
    subtitle: `${TP}.LP_PERFORMANCE_EXPORT_FEATURE`,
    imageSrc: '/images/landing-pages/featured-images/performance_export.webp',
  },
  [LP_PRO_TOOL_ITEM.REQUEST_RED_MASK]: {
    label: `${TP}.LP_REQUEST_RED_MASK`,
    qnaI18nKey: `${TP}.LP_RED_MASK_QNA`,
    title: `${TP}.LP_REQUEST_RED_MASK`,
    subtitle: `${TP}.LP_REQUEST_RED_MASK_INFO`,
    imageSrc: '/images/landing-pages/featured-images/request_red_mask.webp',
  },
};

export const COMPANY_IMAGES = [
  {
    id: 10205,
    slug: 'english-national-opera',
    logo: '1004_english-national-opera.png',
    transparency: false,
    name: 'English National Opera',
  },
  {
    id: 10238,
    slug: 'grange-park-opera',
    logo: '1039_grange-park-opera.png',
    transparency: false,
    name: 'Grange Park Opera',
  },
  {
    id: 9359,
    slug: 'calgary-opera',
    logo: '105_calgary-opera.png',
    transparency: true,
    name: 'Calgary Opera',
  },
  {
    id: 9361,
    slug: 'canadian-opera-company',
    logo: '107_canadian-opera-company.png',
    transparency: true,
    name: 'Canadian Opera Company',
  },
  {
    id: 10420,
    slug: 'royal-opera-house',
    logo: '1205_royal-opera-house.png',
    transparency: false,
    name: 'Royal Opera House',
  },
  {
    id: 9378,
    slug: 'opera-on-the-avalon',
    logo: '125_opera-on-the-avalon.png',
    transparency: true,
    name: 'Opera on the Avalon',
  },
  {
    id: 10569,
    slug: 'welsh-national-opera',
    logo: '1284_welsh-national-opera.png',
    transparency: false,
    name: 'Welsh National Opera ( WNO )',
  },
  {
    id: 9381,
    slug: 'pacific-opera-victoria',
    logo: '128_pacific-opera-victoria.png',
    transparency: false,
    name: 'Pacific Opera Victoria',
  },
  {
    id: 10852,
    slug: 'boheme-opera',
    logo: '1316_boheme-opera.png',
    transparency: false,
    name: 'Boheme Opera NJ',
  },
  {
    id: 10912,
    slug: 'cincinnati-opera',
    logo: '1332_cincinnati-opera.png',
    transparency: false,
    name: 'Cincinnati Opera',
  },
  {
    id: 11095,
    slug: 'houston-grand-opera',
    logo: '1367_houston-grand-opera.png',
    transparency: false,
    name: 'Houston Grand Opera',
  },
  {
    id: 11373,
    slug: 'little-opera-theatre-of-new-york',
    logo: '1380_little-opera-theatre-of-new-york.png',
    transparency: false,
    name: 'Little Opera Theatre of New York',
  },
  {
    id: 11398,
    slug: 'la-opera',
    logo: '1383_la-opera.png',
    transparency: false,
    name: 'LA Opera',
  },
  {
    id: 11466,
    slug: 'lyric-opera-of-chicago',
    logo: '1385_lyric-opera-of-chicago.png',
    transparency: false,
    name: 'Lyric Opera of Chicago',
  },
  {
    id: 11683,
    slug: 'lyric-opera-of-kansas-city',
    logo: '1386_lyric-opera-of-kansas-city.png',
    transparency: false,
    name: 'Lyric Opera of Kansas City',
  },
  {
    id: 11752,
    slug: 'maryland-lyric-opera',
    logo: '1390_maryland-lyric-opera.png',
    transparency: false,
    name: 'Maryland Lyric Opera',
  },
  {
    id: 11794,
    slug: 'minnesota-opera',
    logo: '1394_minnesota-opera.png',
    transparency: false,
    name: 'Minnesota Opera',
  },
  {
    id: 9392,
    slug: 'vancouver-opera',
    logo: '139_vancouver-opera.png',
    transparency: false,
    name: 'Vancouver Opera',
  },
  {
    id: 11854,
    slug: 'nashville-opera',
    logo: '1401_nashville-opera.png',
    transparency: false,
    name: 'Nashville Opera',
  },
  {
    id: 12158,
    slug: 'opera-columbus',
    logo: '1418_opera-columbus.png',
    transparency: false,
    name: 'Opera Columbus',
  },
  {
    id: 12415,
    slug: 'opera-philadelphia',
    logo: '1434_opera-philadelphia.png',
    transparency: false,
    name: 'Opera Philadelphia',
  },
  {
    id: 12647,
    slug: 'opera-southwest',
    logo: '1440_opera-southwest.png',
    transparency: false,
    name: 'Opera Southwest',
  },
  {
    id: 12811,
    slug: 'palm-beach-opera',
    logo: '1455_palm-beach-opera.png',
    transparency: false,
    name: 'Palm Beach Opera',
  },
  {
    id: 12862,
    slug: 'pittsburgh-opera',
    logo: '1461_pittsburgh-opera.png',
    transparency: false,
    name: 'Pittsburgh Opera',
  },
  {
    id: 13157,
    slug: 'san-diego-opera',
    logo: '1475_san-diego-opera.png',
    transparency: false,
    name: 'San Diego Opera',
  },
  {
    id: 13234,
    slug: 'san-francisco-opera',
    logo: '1476_san-francisco-opera.png',
    transparency: false,
    name: 'San Francisco Opera',
  },
  {
    id: 13373,
    slug: 'santa-fe-opera',
    logo: '1477_santa-fe-opera.png',
    transparency: false,
    name: 'Santa Fe Opera',
  },
  {
    id: 13476,
    slug: 'seattle-opera',
    logo: '1479_seattle-opera.png',
    transparency: false,
    name: 'Seattle Opera',
  },
  {
    id: 13779,
    slug: 'the-metropolitan-opera',
    logo: '1496_the-metropolitan-opera.png',
    transparency: false,
    name: 'The Metropolitan Opera',
  },
  {
    id: 14277,
    slug: 'washington-national-opera',
    logo: '1511_washington-national-opera.png',
    transparency: false,
    name: 'Washington National Opera',
  },
  {
    id: 14672,
    slug: 'theater-krefeld-und-monchengladbach',
    logo: '1534_theater-krefeld-und-monchengladbach.png',
    transparency: false,
    name: 'Theater Krefeld und Mönchengladbach',
  },
  {
    id: 14699,
    slug: 'teatro-verdi-di-salerno',
    logo: '1535_teatro-verdi-di-salerno.png',
    transparency: false,
    name: 'Teatro Verdi di Salerno',
  },
  {
    id: 9406,
    slug: 'buhnen-bern',
    logo: '155_buhnen-bern.png',
    transparency: false,
    name: 'Bühnen Bern',
  },
  {
    id: 15153,
    slug: 'irish-national-opera',
    logo: '1568_irish-national-opera.png',
    transparency: false,
    name: 'Irish National Opera',
  },
  {
    id: 9411,
    slug: 'luzerner-theater',
    logo: '160_luzerner-theater.png',
    transparency: false,
    name: 'Luzerner Theater',
  },
  {
    id: 9420,
    slug: 'opernhaus-zurich',
    logo: '169_opernhaus-zurich.png',
    transparency: false,
    name: 'Opernhaus Zürich',
  },
  {
    id: 9425,
    slug: 'nof-nouvel-opera-fribourg-neue-oper-freiburg',
    logo: '174_nof-nouvel-opera-fribourg-neue-oper-freiburg.png',
    transparency: false,
    name: 'NOF - Nouvel Opéra Fribourg - Neue Oper Freiburg',
  },
  {
    id: 9426,
    slug: 'opera-de-lausanne',
    logo: '175_opera-de-lausanne.png',
    transparency: true,
    name: 'Opéra de Lausanne',
  },
  {
    id: 16145,
    slug: 'international-vocal-competitions-hertogenbosch',
    logo: '1819_international-vocal-competitions-hertogenbosch.png',
    transparency: false,
    name: 'International Vocal Competition&#x27;s-Hertogenbosch',
  },
  {
    id: 9432,
    slug: 'theater-basel',
    logo: '182_theater-basel.png',
    transparency: false,
    name: 'Theater Basel',
  },
  {
    id: 16195,
    slug: 'opera-nationala-romana-cluj-napoca',
    logo: '1834_opera-nationala-romana-cluj-napoca.png',
    transparency: false,
    name: 'Opera Națională Română Cluj-Napoca',
  },
  {
    id: 16325,
    slug: 'statne-divadlo-kosice',
    logo: '1842_statne-divadlo-kosice.png',
    transparency: false,
    name: 'Národné divadlo Košice',
  },
  {
    id: 16715,
    slug: 'glyndebourne',
    logo: '1887_glyndebourne.png',
    transparency: false,
    name: 'Glyndebourne',
  },
  {
    id: 9440,
    slug: 'national-centre-for-the-performing-arts',
    logo: '190_national-centre-for-the-performing-arts.png',
    transparency: true,
    name: 'National Centre for the Performing Arts',
  },
  {
    id: 17311,
    slug: 'theater-munster',
    logo: '1932_theater-munster.png',
    transparency: false,
    name: 'Theater Münster',
  },
  {
    id: 17396,
    slug: 'theater-und-orchester-heidelberg',
    logo: '1933_theater-und-orchester-heidelberg.png',
    transparency: false,
    name: 'Theater und Orchester Heidelberg',
  },
  {
    id: 17564,
    slug: 'mascarade-emerging-artists',
    logo: '1949_mascarade-emerging-artists.png',
    transparency: false,
    name: 'Mascarade Emerging Artists',
  },
  {
    id: 17574,
    slug: 'staatsoper-stuttgart',
    logo: '1953_staatsoper-stuttgart.png',
    transparency: false,
    name: 'Stuttgart Opera',
  },
  {
    id: 19538,
    slug: 'cleveland-orchestra',
    logo: '2066_cleveland-orchestra.png',
    transparency: false,
    name: 'Cleveland Orchestra',
  },
  {
    id: 19733,
    slug: 'berliner-philharmoniker',
    logo: '2080_berliner-philharmoniker.png',
    transparency: false,
    name: 'Berliner Philharmoniker',
  },
  {
    id: 19991,
    slug: 'tokyo-nikikai-opera-foundation',
    logo: '2081_tokyo-nikikai-opera-foundation.png',
    transparency: false,
    name: 'Tokyo Nikikai Opera Foundation',
  },
  {
    id: 20350,
    slug: 'civitanova-allopera',
    logo: '2089_civitanova-allopera.png',
    transparency: false,
    name: 'Civitanova all&#x27;Opera',
  },
  {
    id: 9456,
    slug: 'national-moravian-silesian-theatre',
    logo: '209_national-moravian-silesian-theatre.png',
    transparency: true,
    name: 'National Moravian-Silesian Theatre',
  },
  {
    id: 20805,
    slug: 'celeste-productions',
    logo: '2109_celeste-productions.png',
    transparency: false,
    name: 'Céleste Productions',
  },
  {
    id: 9457,
    slug: 'national-theatre-prague',
    logo: '210_national-theatre-prague.png',
    transparency: true,
    name: 'National Theatre Prague',
  },
  {
    id: 21450,
    slug: 'claude-heater-foundation',
    logo: '2159_claude-heater-foundation.png',
    transparency: false,
    name: 'Claude Heater Foundation',
  },
  {
    id: 9462,
    slug: 'slezske-divadlo',
    logo: '216_slezske-divadlo.png',
    transparency: true,
    name: 'Slezské divadlo',
  },
  {
    id: 21727,
    slug: 'chicago-summer-opera',
    logo: '2174_chicago-summer-opera.png',
    transparency: false,
    name: 'Chicago Summer Opera',
  },
  {
    id: 22076,
    slug: 'new-york-philharmonic',
    logo: '2191_new-york-philharmonic.png',
    transparency: false,
    name: 'New York Philharmonic',
  },
  {
    id: 22734,
    slug: 'latvian-national-symphony-orchestra',
    logo: '2203_latvian-national-symphony-orchestra.png',
    transparency: false,
    name: 'Latvian National Symphony Orchestra',
  },
  {
    id: 9466,
    slug: 'aalto-musiktheater-essen',
    logo: '220_aalto-musiktheater-essen.png',
    transparency: true,
    name: 'Aalto-Musiktheater Essen',
  },
  {
    id: 23078,
    slug: 'the-jerusalem-opera',
    logo: '2212_the-jerusalem-opera.png',
    transparency: false,
    name: 'The Jerusalem Opera',
  },
  {
    id: 9467,
    slug: 'anhaltisches-theater-dessau',
    logo: '221_anhaltisches-theater-dessau.png',
    transparency: false,
    name: 'Anhaltisches Theater Dessau',
  },
  {
    id: 23996,
    slug: 'universitat-fur-musik-und-darstellende-kunst-graz',
    logo: '2247_universitat-fur-musik-und-darstellende-kunst-graz.png',
    transparency: false,
    name: 'Universität für Musik und darstellende Kunst Graz',
  },
  {
    id: 9470,
    slug: 'badisches-staatstheater-karlsruhe',
    logo: '224_badisches-staatstheater-karlsruhe.png',
    transparency: false,
    name: 'Badisches Staatstheater Karlsruhe',
  },
  {
    id: 24124,
    slug: 'polska-opera-krolewska',
    logo: '2254_polska-opera-krolewska.png',
    transparency: false,
    name: 'Polska Opera Królewska',
  },
  {
    id: 9476,
    slug: 'bayerische-staatsoper',
    logo: '230_bayerische-staatsoper.png',
    transparency: true,
    name: 'Bayerische Staatsoper',
  },
  {
    id: 27176,
    slug: 'opera-comique',
    logo: '2449_opera-comique.png',
    transparency: false,
    name: 'Opéra Comique',
  },
  {
    id: 27451,
    slug: 'sofia-philharmonic',
    logo: '2453_sofia-philharmonic.png',
    transparency: false,
    name: 'Sofia Philharmonic',
  },
  {
    id: 9491,
    slug: 'deutsche-oper-berlin',
    logo: '246_deutsche-oper-berlin.png',
    transparency: false,
    name: 'Deutsche Oper Berlin',
  },
  {
    id: 9492,
    slug: 'deutsche-oper-am-rhein',
    logo: '247_deutsche-oper-am-rhein.png',
    transparency: false,
    name: 'Deutsche Oper am Rhein',
  },
  {
    id: 9493,
    slug: 'deutsches-nationaltheater-und-staatskapelle-weimar',
    logo: '248_deutsches-nationaltheater-und-staatskapelle-weimar.png',
    transparency: true,
    name: 'Deutsches Nationaltheater und Staatskapelle Weimar',
  },
  {
    id: 28719,
    slug: 'wiener-konzerthaus',
    logo: '2538_wiener-konzerthaus.png',
    transparency: false,
    name: 'Wiener Konzerthaus',
  },
  {
    id: 9509,
    slug: 'hessisches-staatstheater-wiesbaden',
    logo: '265_hessisches-staatstheater-wiesbaden.png',
    transparency: false,
    name: 'Hessisches Staatstheater Wiesbaden',
  },
  {
    id: 9527,
    slug: 'landesbuhnen-sachsen',
    logo: '285_landesbuhnen-sachsen.png',
    transparency: true,
    name: 'Landesbühnen Sachsen',
  },
  {
    id: 9528,
    slug: 'landestheater-coburg',
    logo: '286_landestheater-coburg.png',
    transparency: false,
    name: 'Landestheater Coburg',
  },
  {
    id: 9529,
    slug: 'landestheater-detmold',
    logo: '287_landestheater-detmold.png',
    transparency: false,
    name: 'Landestheater Detmold',
  },
  {
    id: 9531,
    slug: 'theater-und-orchester-neubrandenburg-neustrelitz',
    logo: '289_theater-und-orchester-neubrandenburg-neustrelitz.png',
    transparency: true,
    name: 'Theater und Orchester Neubrandenburg / Neustrelitz',
  },
  {
    id: 9532,
    slug: 'landestheater-niederbayern',
    logo: '290_landestheater-niederbayern.png',
    transparency: true,
    name: 'Landestheater Niederbayern',
  },
  {
    id: 31244,
    slug: 'polish-national-radio-symphony-orchestra',
    logo: '2928_polish-national-radio-symphony-orchestra.png',
    transparency: false,
    name: 'Polish National Radio Symphony Orchestra',
  },
  {
    id: 9537,
    slug: 'mainfranken-theater-wurzburg',
    logo: '295_mainfranken-theater-wurzburg.png',
    transparency: true,
    name: 'Mainfranken Theater Würzburg',
  },
  {
    id: 9538,
    slug: 'mecklenburgisches-staatstheater-schwerin',
    logo: '297_mecklenburgisches-staatstheater-schwerin.png',
    transparency: false,
    name: 'Mecklenburgisches Staatstheater',
  },
  {
    id: 9539,
    slug: 'staatstheater-meiningen',
    logo: '298_staatstheater-meiningen.png',
    transparency: true,
    name: 'Staatstheater Meiningen',
  },
  {
    id: 9546,
    slug: 'musiktheater-im-revier',
    logo: '305_musiktheater-im-revier.png',
    transparency: false,
    name: 'Musiktheater im Revier',
  },
  {
    id: 31394,
    slug: 'munchens-kleinstes-opernhaus-in-der-pasinger-fabrik',
    logo: '3078_munchens-kleinstes-opernhaus-in-der-pasinger-fabrik.png',
    transparency: false,
    name: 'Münchens Kleinstes Opernhaus in der Pasinger Fabrik',
  },
  {
    id: 9548,
    slug: 'staatstheater-am-gartnerplatz',
    logo: '307_staatstheater-am-gartnerplatz.png',
    transparency: true,
    name: 'Staatstheater am Gärtnerplatz',
  },
  {
    id: 9550,
    slug: 'nationaltheater-mannheim',
    logo: '309_nationaltheater-mannheim.png',
    transparency: false,
    name: 'Mannheim National Theater',
  },
  {
    id: 9555,
    slug: 'oldenburgisches-staatstheater',
    logo: '314_oldenburgisches-staatstheater.png',
    transparency: false,
    name: 'Oldenburgisches Staatstheater',
  },
  {
    id: 9556,
    slug: 'oper-frankfurt',
    logo: '315_oper-frankfurt.png',
    transparency: false,
    name: 'Oper Frankfurt',
  },
  {
    id: 9557,
    slug: 'oper-halle',
    logo: '316_oper-halle.png',
    transparency: false,
    name: 'Oper Halle',
  },
  {
    id: 9558,
    slug: 'oper-koln',
    logo: '317_oper-koln.png',
    transparency: false,
    name: 'Oper Köln',
  },
  {
    id: 9575,
    slug: 'saarlandisches-staatstheater',
    logo: '335_saarlandisches-staatstheater.png',
    transparency: false,
    name: 'Saarländisches Staatstheater',
  },
  {
    id: 31692,
    slug: 'landestheater-linz-musiktheater',
    logo: '3376_landestheater-linz-musikthetaer.png',
    transparency: false,
    name: 'Landestheater Linz Musikthetaer',
  },
  {
    id: 9586,
    slug: 'staatsoper-hamburg',
    logo: '346_staatsoper-hamburg.png',
    transparency: false,
    name: 'Staatsoper Hamburg',
  },
  {
    id: 9588,
    slug: 'state-opera-berlin',
    logo: '348_state-opera-berlin.png',
    transparency: false,
    name: 'Staatsoper Unter den Linden',
  },
  {
    id: 9590,
    slug: 'staatstheater-braunschweig',
    logo: '350_staatstheater-braunschweig.png',
    transparency: true,
    name: 'Staatstheater Braunschweig',
  },
  {
    id: 9592,
    slug: 'staatstheater-darmstadt',
    logo: '352_staatstheater-darmstadt.png',
    transparency: false,
    name: 'Staatstheater Darmstadt',
  },
  {
    id: 9593,
    slug: 'staatstheater-kassel',
    logo: '353_staatstheater-kassel.png',
    transparency: true,
    name: 'Staatstheater Kassel',
  },
  {
    id: 9594,
    slug: 'staatstheater-mainz',
    logo: '354_staatstheater-mainz.png',
    transparency: false,
    name: 'Staatstheater Mainz',
  },
  {
    id: 9290,
    slug: 'salzburger-festspiele',
    logo: '35_salzburger-festspiele.png',
    transparency: true,
    name: 'Salzburg Festival',
  },
  {
    id: 9600,
    slug: 'semperoper-dresden',
    logo: '362_semperoper-dresden.png',
    transparency: false,
    name: 'Semperoper Dresden',
  },
  {
    id: 9602,
    slug: 'staatstheater-augsburg',
    logo: '364_staatstheater-augsburg.png',
    transparency: true,
    name: 'Staatstheater Augsburg',
  },
  {
    id: 9603,
    slug: 'theater-bielefeld',
    logo: '365_theater-bielefeld.png',
    transparency: false,
    name: 'Theater Bielefeld',
  },
  {
    id: 9604,
    slug: 'theater-bonn',
    logo: '366_theater-bonn.png',
    transparency: false,
    name: 'Theater Bonn',
  },
  {
    id: 9605,
    slug: 'theater-bremen',
    logo: '367_theater-bremen.png',
    transparency: false,
    name: 'Theater Bremen',
  },
  {
    id: 9607,
    slug: 'theater-dortmund',
    logo: '369_theater-dortmund.png',
    transparency: true,
    name: 'Theater Dortmund',
  },
  {
    id: 9291,
    slug: 'salzburger-landestheater',
    logo: '36_salzburger-landestheater.png',
    transparency: true,
    name: 'Salzburger Landestheater',
  },
  {
    id: 9608,
    slug: 'theater-erfurt',
    logo: '370_theater-erfurt.png',
    transparency: false,
    name: 'Theater Erfurt',
  },
  {
    id: 9609,
    slug: 'theater-freiburg',
    logo: '372_theater-freiburg.png',
    transparency: false,
    name: 'Theater Freiburg',
  },
  {
    id: 9614,
    slug: 'theater-kiel',
    logo: '377_theater-kiel.png',
    transparency: false,
    name: 'Theater Kiel',
  },
  {
    id: 9615,
    slug: 'theater-koblenz',
    logo: '378_theater-koblenz.png',
    transparency: false,
    name: 'Theater Koblenz',
  },
  {
    id: 9616,
    slug: 'theater-lubeck',
    logo: '379_theater-lubeck.png',
    transparency: false,
    name: 'Theater Lübeck',
  },
  {
    id: 9618,
    slug: 'theater-magdeburg',
    logo: '381_theater-magdeburg.png',
    transparency: false,
    name: 'Theater Magdeburg',
  },
  {
    id: 32157,
    slug: 'omroep-muziek',
    logo: '3841_omroep-muziek.png',
    transparency: false,
    name: 'Omroep Muziek',
  },
  {
    id: 9623,
    slug: 'theater-regensburg',
    logo: '386_theater-regensburg.png',
    transparency: false,
    name: 'Theater Regensburg',
  },
  {
    id: 9625,
    slug: 'ulm-theater',
    logo: '388_theater-ulm.png',
    transparency: false,
    name: 'Ulm Theater',
  },
  {
    id: 9632,
    slug: 'wuppertaler-buhnen',
    logo: '397_wuppertaler-buhnen.png',
    transparency: false,
    name: 'Wuppertaler Bühnen und Sinfonieorchester GmbH',
  },
  {
    id: 32328,
    slug: 'abu-dhabi-classics',
    logo: '4018_abu-dhabi-classics.png',
    transparency: false,
    name: 'Abu Dhabi Classics',
  },
  {
    id: 32379,
    slug: 'new-york-festival-of-song',
    logo: '4069_new-york-festival-of-song.png',
    transparency: false,
    name: 'New York Festival of Song',
  },
  {
    id: 9649,
    slug: 'royal-danish-opera',
    logo: '414_royal-danish-opera.png',
    transparency: false,
    name: 'Royal Danish Opera',
  },
  {
    id: 9652,
    slug: 'estonian-national-opera',
    logo: '417_estonian-national-opera.png',
    transparency: false,
    name: 'Estonian National Opera',
  },
  {
    id: 32499,
    slug: 'the-shepherd-school-of-music-rice-university',
    logo: '4189_the-shepherd-school-of-music-rice-university.png',
    transparency: false,
    name: 'The Shepherd School of Music Rice University',
  },
  {
    id: 9295,
    slug: 'stadttheater-klagenfurt',
    logo: '41_stadttheater-klagenfurt.png',
    transparency: false,
    name: 'Stadttheater Klagenfurt',
  },
  {
    id: 32579,
    slug: 'fort-worth-symphony-orchestra',
    logo: '4269_fort-worth-symphony-orchestra.png',
    transparency: false,
    name: 'Fort Worth Symphony Orchestra',
  },
  {
    id: 9662,
    slug: 'fundacion-baluarte',
    logo: '427_fundacion-baluarte.png',
    transparency: false,
    name: 'Fundación Baluarte',
  },
  {
    id: 32594,
    slug: 'musikverein-graz',
    logo: '4284_musikverein-graz.png',
    transparency: false,
    name: 'Musikverein Graz',
  },
  {
    id: 9680,
    slug: 'palau-de-les-arts-reina-sofia',
    logo: '445_palau-de-les-arts-reina-sofia.png',
    transparency: false,
    name: 'Palau de les Arts Reina Sofia',
  },
  {
    id: 9298,
    slug: 'musiktheater-an-der-wien',
    logo: '44_musiktheater-an-der-wien.png',
    transparency: false,
    name: 'MusikTheater an der Wien',
  },
  {
    id: 32841,
    slug: 'tonkunstler-orchester',
    logo: '4531_tonkunstler-orchester.png',
    transparency: false,
    name: 'Tonkünstler-Orchester',
  },
  {
    id: 9691,
    slug: 'teatro-maestranza',
    logo: '456_teatro-maestranza.png',
    transparency: false,
    name: 'Teatro Maestranza',
  },
  {
    id: 9692,
    slug: 'teatro-lirico-nacional-de-la-zarzuela',
    logo: '457_teatro-lirico-nacional-de-la-zarzuela.png',
    transparency: false,
    name: 'Teatro de la Zarzuela',
  },
  {
    id: 9694,
    slug: 'opera-de-butxaca-i-nova-creacio',
    logo: '459_opera-de-butxaca-i-nova-creacio.png',
    transparency: false,
    name: 'Òpera de Butxaca i Nova Creació',
  },
  {
    id: 32995,
    slug: 'music-of-the-baroque',
    logo: '4685_music-of-the-baroque.png',
    transparency: false,
    name: 'Music of the Baroque',
  },
  {
    id: 9300,
    slug: 'tiroler-landestheater',
    logo: '46_tiroler-landestheater.png',
    transparency: true,
    name: 'Tiroler Landestheater',
  },
  {
    id: 33078,
    slug: 'hochschule-fur-musik-und-darstellende-kunst-frankfurt',
    logo: '4768_hochschule-fur-musik-und-darstellende-kunst-frankfurt.png',
    transparency: false,
    name: 'Hochschule für Musik und Darstellende Kunst Frankfurt',
  },
  {
    id: 9713,
    slug: 'arcal',
    logo: '481_arcal.png',
    transparency: false,
    name: 'Arcal Compagnie Lyrique',
  },
  {
    id: 9742,
    slug: 'les-talens-lyriques',
    logo: '513_les-talens-lyriques.png',
    transparency: false,
    name: 'Les Talens Lyriques',
  },
  {
    id: 9744,
    slug: 'les-musiciens-du-louvre',
    logo: '515_les-musiciens-du-louvre.png',
    transparency: true,
    name: 'Les Musiciens du Louvre',
  },
  {
    id: 9305,
    slug: 'wiener-staatsoper',
    logo: '51_wiener-staatsoper.png',
    transparency: true,
    name: 'Wiener Staatsoper',
  },
  {
    id: 33587,
    slug: 'philharmonia-baroque-orchestra-and-chorale',
    logo: '5277_philharmonia-baroque-orchestra-and-chorale.png',
    transparency: false,
    name: 'Philharmonia Baroque Orchestra &amp; Chorale',
  },
  {
    id: 33621,
    slug: 'boston-early-music-festival',
    logo: '5311_boston-early-music-festival.png',
    transparency: false,
    name: 'Boston Early Music Festival',
  },
  {
    id: 9761,
    slug: 'opera-orchestre-national-de-montpellier',
    logo: '533_opera-national-de-montpellier.png',
    transparency: true,
    name: 'Opéra National de Montpellier',
  },
  {
    id: 9762,
    slug: 'opera-national-de-paris',
    logo: '534_opera-national-de-paris.png',
    transparency: true,
    name: 'Opéra National de Paris',
  },
  {
    id: 33666,
    slug: 'merola-opera-program',
    logo: '5356_merola-opera-program.png',
    transparency: false,
    name: 'Merola Opera Program',
  },
  {
    id: 9763,
    slug: 'opera-national-du-rhin',
    logo: '535_opera-national-du-rhin.png',
    transparency: false,
    name: 'Opéra National du Rhin',
  },
  {
    id: 9764,
    slug: 'opera-nice-cote-dazur',
    logo: '536_opera-nice-cote-dazur.png',
    transparency: false,
    name: 'Opéra Nice Côte d&#x27;Azur',
  },
  {
    id: 33689,
    slug: 'le-concert-de-la-loge',
    logo: '5379_le-concert-de-la-loge.png',
    transparency: false,
    name: 'Le Concert de la Loge',
  },
  {
    id: 9770,
    slug: 'opera-de-lyon',
    logo: '542_opera-de-lyon.png',
    transparency: false,
    name: 'Opéra de Lyon',
  },
  {
    id: 9771,
    slug: 'opera-de-massy',
    logo: '543_opera-de-massy.png',
    transparency: false,
    name: 'Opéra de Massy',
  },
  {
    id: 9776,
    slug: 'opera-de-toulon',
    logo: '549_opera-de-toulon.png',
    transparency: true,
    name: 'Opéra de Toulon',
  },
  {
    id: 9777,
    slug: 'opera-de-tours',
    logo: '550_opera-de-tours.png',
    transparency: true,
    name: 'Opéra de Tours',
  },
  {
    id: 33870,
    slug: 'detroit-opera',
    logo: '5560_detroit-opera.png',
    transparency: false,
    name: 'Detroit Opera',
  },
  {
    id: 33899,
    slug: 'ars-minerva',
    logo: '5590_ars-minerva.png',
    transparency: false,
    name: 'Ars Minerva',
  },
  {
    id: 33947,
    slug: 'associazione-xxi-secolo',
    logo: '5638_associazione-xxi-secolo.png',
    transparency: false,
    name: 'Associazione XXI Secolo',
  },
  {
    id: 33950,
    slug: 'rotterdam-philharmonic-orchestra',
    logo: '5641_rotterdam-philharmonic-orchestra.png',
    transparency: false,
    name: 'Rotterdam Philharmonic Orchestra',
  },
  {
    id: 9794,
    slug: 'theatre-de-caen',
    logo: '569_theatre-de-caen.png',
    transparency: false,
    name: 'Théâtre de Caen',
  },
  {
    id: 9797,
    slug: 'theatre-des-champs-elysees',
    logo: '573_theatre-des-champs-elysees.png',
    transparency: false,
    name: 'Théâtre des Champs-Elysées',
  },
  {
    id: 9798,
    slug: 'opera-national-du-capitole',
    logo: '574_opera-national-du-capitole.png',
    transparency: true,
    name: 'Opéra national du Capitole',
  },
  {
    id: 9799,
    slug: 'theatre-du-chatelet',
    logo: '575_theatre-du-chatelet.png',
    transparency: true,
    name: 'Théâtre du Châtelet',
  },
  {
    id: 9802,
    slug: 'greek-national-opera',
    logo: '578_greek-national-opera.png',
    transparency: false,
    name: 'Greek National Opera',
  },
  {
    id: 34091,
    slug: 'schonbrunn-palace-orchestra',
    logo: '5797_schonbrunn-palace-orchestra.png',
    transparency: false,
    name: 'Schönbrunn Palace Orchestra',
  },
  {
    id: 9806,
    slug: 'opera-hong-kong',
    logo: '582_opera-hong-kong.png',
    transparency: false,
    name: 'Opera Hong Kong',
  },
  {
    id: 9812,
    slug: 'hnk-zagreb',
    logo: '588_hnk-zagreb.png',
    transparency: false,
    name: 'HNK Zagreb',
  },
  {
    id: 9313,
    slug: 'opera-australia',
    logo: '59_opera-australia.png',
    transparency: false,
    name: 'Opera Australia',
  },
  {
    id: 9263,
    slug: 'teatro-colon',
    logo: '5_teatro-colon.png',
    transparency: true,
    name: 'Teatro Colón',
  },
  {
    id: 34354,
    slug: 'camerata-salzburg',
    logo: '6060_camerata-salzburg.png',
    transparency: false,
    name: 'Camerata Salzburg',
  },
  {
    id: 34494,
    slug: 'accademia-musicale-bernardo-de-muro',
    logo: '6203_accademia-musicale-bernardo-de-muro.png',
    transparency: false,
    name: 'Accademia musicale Bernardo De Muro',
  },
  {
    id: 9849,
    slug: 'accademia-nazionale-di-santa-cecilia',
    logo: '626_accademia-nazionale-di-santa-cecilia.png',
    transparency: false,
    name: 'Accademia Nazionale di Santa Cecilia',
  },
  {
    id: 34626,
    slug: 'ndr-elbphilharmonie-orchester',
    logo: '6335_ndr-elbphilharmonie-orchester.png',
    transparency: false,
    name: 'NDR Elbphilharmonie Orchester',
  },
  {
    id: 9865,
    slug: 'fondazione-arena-di-verona',
    logo: '642_fondazione-arena-di-verona.png',
    transparency: false,
    name: 'Fondazione Arena di Verona',
  },
  {
    id: 9866,
    slug: 'fondazione-petruzzelli',
    logo: '643_fondazione-petruzzelli.png',
    transparency: false,
    name: 'Fondazione Petruzzelli',
  },
  {
    id: 9873,
    slug: 'teatro-la-fenice',
    logo: '651_la-fenice.png',
    transparency: false,
    name: 'La Fenice',
  },
  {
    id: 34835,
    slug: 'mystic-side-opera-co',
    logo: '6550_mystic-side-opera-co.png',
    transparency: false,
    name: 'Mystic Side Opera Co',
  },
  {
    id: 34887,
    slug: 'patina-gallery',
    logo: '6602_patina-gallery.png',
    transparency: false,
    name: 'Patina Gallery',
  },
  {
    id: 9882,
    slug: 'fondazione-haydn-di-bolzano-e-trento',
    logo: '660_fondazione-haydn-di-bolzano-e-trento.png',
    transparency: false,
    name: 'Fondazione Haydn di Bolzano e Trento',
  },
  {
    id: 9887,
    slug: 'fondazione-del-teatro-del-maggio-musicale-fiorentino',
    logo: '665_fondazione-del-teatro-del-maggio-musicale-fiorentino.png',
    transparency: false,
    name: 'Fondazione del Teatro del Maggio Musicale Fiorentino',
  },
  {
    id: 9888,
    slug: 'teatro-carlo-felice',
    logo: '666_teatro-carlo-felice.png',
    transparency: false,
    name: 'Teatro Carlo Felice',
  },
  {
    id: 9903,
    slug: 'teatro-comunale-di-bologna',
    logo: '682_teatro-comunale-di-bologna.png',
    transparency: false,
    name: 'Teatro Comunale di Bologna',
  },
  {
    id: 9907,
    slug: 'fondazione-teatro-donizetti',
    logo: '686_fondazione-teatro-donizetti.png',
    transparency: false,
    name: 'Fondazione Teatro Donizetti',
  },
  {
    id: 9908,
    slug: 'teatro-fraschini-di-pavia',
    logo: '687_teatro-fraschini-di-pavia.png',
    transparency: false,
    name: 'Teatro Fraschini di Pavia',
  },
  {
    id: 9910,
    slug: 'teatro-goldoni',
    logo: '689_teatro-goldoni.png',
    transparency: false,
    name: 'Teatro Goldoni',
  },
  {
    id: 9322,
    slug: 'victorian-opera',
    logo: '68_victorian-opera.png',
    transparency: false,
    name: 'Victorian Opera',
  },
  {
    id: 9912,
    slug: 'teatro-lirico-sperimentale-di-spoleto',
    logo: '691_teatro-lirico-sperimentale-di-spoleto.png',
    transparency: false,
    name: 'Teatro Lirico Sperimentale di Spoleto',
  },
  {
    id: 9915,
    slug: 'teatro-massimo-di-palermo',
    logo: '694_teatro-massimo-di-palermo.png',
    transparency: false,
    name: 'Teatro Massimo di Palermo',
  },
  {
    id: 9924,
    slug: 'teatro-verdi-di-trieste',
    logo: '703_teatro-verdi-di-trieste.png',
    transparency: false,
    name: 'Teatro Lirico Giuseppe Verdi di Trieste',
  },
  {
    id: 9926,
    slug: 'teatro-alla-scala',
    logo: '706_teatro-alla-scala.png',
    transparency: false,
    name: 'Teatro alla Scala',
  },
  {
    id: 9928,
    slug: 'teatro-dellopera-di-roma',
    logo: '708_teatro-dellopera-di-roma.png',
    transparency: false,
    name: 'Teatro dell&#x27;Opera di Roma',
  },
  {
    id: 35232,
    slug: 'orquestra-sinfonica-do-estado-de-sao-paulo',
    logo: '7117_orquestra-sinfonica-do-estado-de-sao-paulo.png',
    transparency: false,
    name: 'Orquestra Sinfônica do Estado de São Paulo',
  },
  {
    id: 9931,
    slug: 'teatro-di-san-carlo',
    logo: '711_teatro-di-san-carlo.png',
    transparency: false,
    name: 'San Carlo Theater',
  },
  {
    id: 9935,
    slug: 'biwako-hall',
    logo: '715_biwako-hall.png',
    transparency: false,
    name: 'Biwako Hall',
  },
  {
    id: 9936,
    slug: 'new-national-theatre-tokyo',
    logo: '716_new-national-theatre-tokyo.png',
    transparency: false,
    name: 'New National Theatre Tokyo',
  },
  {
    id: 35334,
    slug: 'opera-connect',
    logo: '7219_opera-connect.png',
    transparency: false,
    name: 'Opera Connect',
  },
  {
    id: 9943,
    slug: 'korea-national-opera',
    logo: '723_korea-national-opera.png',
    transparency: false,
    name: 'Korea National Opera',
  },
  {
    id: 9952,
    slug: 'lithuanian-national-opera-and-ballet-theatre',
    logo: '733_lithuanian-national-opera-and-ballet-theatre.png',
    transparency: false,
    name: 'Lithuanian National Opera and Ballet Theatre',
  },
  {
    id: 35507,
    slug: 'opera-west-society',
    logo: '7392_opera-west-society.png',
    transparency: false,
    name: 'Opera West Society',
  },
  {
    id: 9961,
    slug: 'opera-de-monte-carlo',
    logo: '742_opera-de-monte-carlo.png',
    transparency: false,
    name: 'Opéra de Monte-Carlo',
  },
  {
    id: 9975,
    slug: 'de-nationale-opera',
    logo: '756_de-nationale-opera.png',
    transparency: false,
    name: 'Dutch National Opera &amp; Ballet',
  },
  {
    id: 35774,
    slug: 'pocket-opera-company',
    logo: '7659_pocket-opera-company.png',
    transparency: false,
    name: 'Pocket Opera Company',
  },
  {
    id: 9330,
    slug: 'la-monnaiede-munt',
    logo: '76_la-monnaiede-munt.png',
    transparency: false,
    name: 'La Monnaie/De Munt',
  },
  {
    id: 9988,
    slug: 'nederlandse-reisopera',
    logo: '770_nederlandse-reisopera.png',
    transparency: false,
    name: 'Nederlandse Reisopera',
  },
  {
    id: 35840,
    slug: 'athens-philharmonic',
    logo: '7725_athens-philharmonic.png',
    transparency: false,
    name: 'Athens Philharmonic',
  },
  {
    id: 9995,
    slug: 'opera-zuid',
    logo: '777_opera-zuid.png',
    transparency: false,
    name: 'Opera Zuid',
  },
  {
    id: 10004,
    slug: 'bergen-national-opera',
    logo: '786_bergen-national-opera.png',
    transparency: false,
    name: 'Bergen National Opera',
  },
  {
    id: 10005,
    slug: 'den-norske-opera',
    logo: '787_den-norske-opera.png',
    transparency: false,
    name: 'Den Norske Opera',
  },
  {
    id: 10012,
    slug: 'kilden-opera',
    logo: '794_kilden-opera.png',
    transparency: false,
    name: 'Kilden Opera',
  },
  {
    id: 10021,
    slug: 'trondheim-symfoniorkester-and-opera',
    logo: '803_trondheim-symfoniorkester-and-opera.png',
    transparency: false,
    name: 'Trondheim Symfoniorkester &amp; Opera',
  },
  {
    id: 10024,
    slug: 'new-zealand-opera',
    logo: '807_new-zealand-opera.png',
    transparency: false,
    name: 'New Zealand Opera',
  },
  {
    id: 10026,
    slug: 'royal-opera-house-of-muscat',
    logo: '809_royal-opera-house-of-muscat.png',
    transparency: false,
    name: 'Royal Opera House of Muscat',
  },
  {
    id: 9335,
    slug: 'opera-royal-de-wallonie-liege',
    logo: '81_opera-royal-de-wallonie-liege.png',
    transparency: true,
    name: 'Royal Opera of Wallonia',
  },
  {
    id: 10042,
    slug: 'teatr-wielki-opera-narodowa',
    logo: '825_teatr-wielki-opera-narodowa.png',
    transparency: false,
    name: 'Teatr Wielki - Opera Narodowa',
  },
  {
    id: 10089,
    slug: 'folkoperan',
    logo: '883_folkoperan.png',
    transparency: false,
    name: 'Folkoperan',
  },
  {
    id: 10091,
    slug: 'goteborgsoperan',
    logo: '885_goteborgsoperan.png',
    transparency: false,
    name: 'GöteborgsOperan',
  },
  {
    id: 10094,
    slug: 'malmo-opera',
    logo: '888_malmo-opera.png',
    transparency: false,
    name: 'Malmö Opera',
  },
  {
    id: 9342,
    slug: 'sofia-opera-and-ballet',
    logo: '88_sofia-opera-and-ballet.png',
    transparency: false,
    name: 'Sofia Opera & Ballet',
  },
  {
    id: 10096,
    slug: 'norrlandsoperan',
    logo: '890_norrlandsoperan.png',
    transparency: false,
    name: 'NorrlandsOpera',
  },
  {
    id: 10108,
    slug: 'opera-and-ballet-of-slovene-national-theatre-maribor',
    logo: '902_opera-and-ballet-of-slovene-national-theatre-maribor.png',
    transparency: false,
    name: 'Opera and Ballet of Slovene National Theatre Maribor',
  },
  {
    id: 10109,
    slug: 'sng-opera-in-balet-ljubljana',
    logo: '903_sng-opera-in-balet-ljubljana.png',
    transparency: false,
    name: 'SNG Opera in balet Ljubljana',
  },
  {
    id: 10112,
    slug: 'slovak-national-theatre',
    logo: '907_slovak-national-theatre.png',
    transparency: false,
    name: 'Slovak National Theatre',
  },
  {
    id: 9344,
    slug: 'state-opera-plovdiv',
    logo: '90_state-opera-plovdiv.png',
    transparency: false,
    name: 'State Opera Plovdiv',
  },
];
